<template>
  <div id="transitionrightenter">
    <transition>
      <slot></slot>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TransitionRightEnter',
  components: {

  },
  data () {
    return {

    }
  },
  methods: {},
}
</script>

<style scoped>
.v-enter-active {
  animation: moveIn 0.4s ease;
}

.v-leave-active {
  animation: moveOut 0.2s ease;
}
.v-move {
  animation: moveOut 0.2s ease;
}
@keyframes moveIn {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  50% {
    transform: translateX(-10px);
    opacity: 0.7;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes moveOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}
</style>

