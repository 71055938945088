import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    meta: {
      title: '佐治家株式會社',
    },
    // component: () => import('../views/Index.vue')
    component: () => import('../views/tech/finger/FingerView.vue')
  },
  {
    path: '/download',
    meta: {
      title: 'Download',
    },
    component: () => import('../views/download/DownloadView.vue'),
  },
  {
    path: '/info',
    name: 'InfoView',
    component: () => import('../views/information/InfoView.vue'),
    children: [
      {
        path: 'aIRobot',
        name: 'AIRobot',
        meta: {
          title: 'AI Robot',
        },
        component: () => import('../views/information/AIRobot.vue'),
      },
      {
        path: 'aPIinfo',
        name: 'APIinfo',
        meta: {
          title: 'API Information',
        },
        component: () => import('../views/information/APIinfo.vue'),
      },
      {
        path: 'butlerService',
        name: 'ButlerService',
        meta: {
          title: 'Butler Service',
        },
        component: () => import('../views/information/ButlerService.vue'),
      },
      {
        path: 'fingerSocial',
        name: 'FingerSocial',
        meta: {
          title: 'Finger Social',
        },
        component: () => import('../views/information/FingerSocial.vue'),
      },
      {
        path: 'howFinger',
        name: 'HowFinger',
        meta: {
          title: 'Finger Trading',
        },
        component: () => import('../views/information/HowFinger.vue'),
      },
      {
        path: 'manageSystem',
        name: 'ManageSystem',
        meta: {
          title: 'Manage System',
        },
        component: () => import('../views/information/ManageSystem.vue'),
      },
      {
        path: 'securityInfo',
        name: 'SecurityInfo',
        meta: {
          title: 'Security Information',
        },
        component: () => import('../views/information/SecurityInfo.vue'),
      },
      {
        path: 'workForMyself',
        name: 'WorkForMyself',
        meta: {
          title: 'Information',
        },
        component: () => import('../views/information/WorkForMyself.vue'),
      },
    ]
  },
  {
    path: '/service',
    name: 'ServiceView',
    component: () => import('../views/service/ServiceView.vue'),
    children: [
      {
        path: '',
        meta: {
          title: 'Service',
        },
        component: () => import('../views/service/investor/InvestorIndex.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/service/investor/stocks/StocksIndex.vue'),
          },
          {
            path: 'stocks',
            meta: {
              title: 'Stocks',
            },
            component: () => import('../views/service/investor/stocks/StocksIndex.vue'),
          },
          {
            path: 'forex',
            meta: {
              title: 'Forex',
            },
            component: () => import('../views/service/investor/forex/ForexView.vue'),
          },
          {
            path: 'futures',
            meta: {
              title: 'Futures',
            },
            component: () => import('../views/service/investor/futures/FuturesIndex.vue'),
          },
          {
            path: 'funds',
            meta: {
              title: 'Funds',
            },
            component: () => import('../views/service/investor/funds/FundsIndex.vue'),
          },
          {
            path: 'bonds',
            meta: {
              title: 'Bonds',
            },
            component: () => import('../views/service/investor/bonds/BondIndex.vue'),
          },
          {
            path: 'etf',
            meta: {
              title: 'ETF',
            },
            component: () => import('../views/service/investor/etf/ETFIndex.vue'),
          },
        ]
      },
      {
        path: 'broker',
        name: 'BrokerIndex',
        meta: {
          title: 'Broker',
        },
        component: () => import('../views/service/broker/BrokerIndex.vue'),
      },
      {
        path: 'analyst',
        meta: {
          title: 'Analyst',
        },
        name: 'AnalystIndex',
        component: () => import('../views/service/analyst/AnalystIndex.vue'),
      },
      {
        path: 'brokerageHouse',
        meta: {
          title: 'Brokerage House',
        },
        name: 'BrokerHouseIndex',
        component: () => import('../views/service/brokerageHouse/BrokerHouseIndex.vue'),
      },
      {
        path: 'developer',
        meta: {
          title: 'Developer',
        },
        name: 'DevelopIndex',
        component: () => import('../views/service/develop/DevelopIndex.vue'),
      },

    ]
  },
  {
    path: '/technology',
    name: 'TechnologyIndex',
    component: () => import('../views/tech/Index.vue'),
    children: [
      {
        path: '',
        meta: {
          title: 'HomepageView',
        },
        name: 'TechnologyIndex',
        component: () => import('../views/tech/finger/FingerView.vue'),
      },
      {
        path: 'finger',
        meta: {
          title: 'Finger Trader',
        },
        name: 'FingerView',
        component: () => import('../views/tech/finger/FingerView.vue'),
      },
      {
        path: 'dataHub',
        meta: {
          title: 'Data Hub',
        },
        name: 'DataHubView',
        component: () => import('../views/tech/dataHub/DataHubView.vue'),
      },
      {
        path: 'visual',
        meta: {
          title: 'Visual Learning',
        },
        name: 'VisualView',
        component: () => import('../views/tech/visual/VisualView.vue'),
      },
    ]
  },

  {
    path: '/datahub',
    name: 'CommunityView',
    component: () => import('../views/tech/dataHub/DataHubView.vue'),
    children: [
      {
        path: '',
        meta: {
          title: 'Social',
        },
        name: 'CommunityIndex',
        component: () => import('../views/community/CommunityIndex.vue')
      },
      {
        path: 'news',
        meta: {
          title: 'News',
        },
        name: 'NewsIndex',
        component: () => import('../views/community/news/NewsIndex.vue')
      },
      {
        path: 'flash',
        meta: {
          title: 'Flash',
        },
        name: 'FlashIndex',
        component: () => import('../views/community/flash/FlashIndex.vue')
      },
      {
        path: 'news/:id',
        name: 'NewsDetail',
        component: () => import('../views/community/components/NewsDetail.vue')
      },
      {
        path: 'flash/:id',
        name: 'FlashDetail',
        component: () => import('../views/community/components/FlashDetail.vue')
      }
    ]
  },
  {
    path: '/openAccountForm',
    meta: {
      title: 'Open Account',
    },
    name: 'DownloadView',
    component: () => import('../views/openAccount/OpenAccount.vue'),
  },
  {
    path: '/adv',
    meta: {
      title: 'Advertise',
    },
    name: 'AdvertiseView',
    component: () => import('../views/advertise/AdvertiseView.vue'),
  },
  {
    path: '/login',
    meta: {
      title: 'Login',
    },
    name: 'loginView',
    component: () => import('../views/login/login.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router