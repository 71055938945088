export const lang = {
  //---------------------按鈕-------------------------
  back: '後退',
  save: '保存',
  //--------------------navbar------------------------
  navMenu:{
    //--------------------navbar------------------------
    logoText: '佐治家',
    logoText2: '株式會社',
  menu1: '投资',
    menu11: 'Finger',
    menu12: 'Data hub',
    menu13: 'Visual Learning',
    menu11des: 'Finger 是一個涵蓋全球超過 10 萬種交易產品的綜合交易系統',
    menu12des: '提供各類數據的全球支援',
    menu13des: '透過 AI 提供電腦視覺解決方案及深度學習。',
  menu2: '科技',
  menu3: '資產',

  service: '服务',
    forInvestor:'投資人專區',
    forBroker:"經紀人專區",
    forAnalyst:'分析師專區',
    forBrokerageHouse:"經紀公司專區",
    forDeveloper:'開發者專區',
    forInvestordes:'我们对接了全世界不同交易所的上万种交易商品',
    forBrokerdes:"为经纪人，我们提供了完整的营销方案及财务后台，并同时向全球的经纪商申请了最优惠的折扣",
    forAnalystdes:'经营您的账号，我们提供了完善的付费观看功能',
    forBrokerageHousedes:"通过API,我们为经纪商提供了可定制的交易服务",
    forDeveloperdes:'我们为开发者提供了众多api,让你可以灵活的开发出各式各样的应用',
  },
  foot:{
    text1: '佐治家株式會社(Sargia Inc.)為佐治家族辦公室，旗下管理的資產均為佐治家族私有資產，我們不對外募資。',
    text2: '線上交易股票、選擇權、期貨、貨幣、外國證券和固定收益產品可能存在重大損失風險。',
    text3: 'Finger Trading® Finger News® 為George Family Office的服務品牌和/或註冊商標，屬於佐治家株式會社(George Family Office LLC, Sargia)所有。任何聲明的證明文件和統計數據將根據要求提供。顯示的交易代碼僅作為示範用途，並無意構成任何推薦。'
  },
  //-----------------------finger-----------------------
  finger: {
    text1: '一個即時交易系統，涵蓋10萬種產品，為全球200多家交易所和做市商提供綜合系統服務。',
  },
  //-----------------------datahub-----------------------
  datahub: {
    text1: '現代化',
    text2: '華爾街',
    text3: '一次一個市場。',
    text4: "在datahub.io，我們的使命是現代化金融業。我們相信這個行業運行在舊有的技術上，我們來改變這一切。",
    text5: "我們提供超過100,000種產品的即時數據，包括全球股票、期貨、外匯等，支援200多家交易所和做市商，還有1,000多家新聞網站。"
  },
  visual: {
      text1: '透過我們的演算法，',
      text2: '讓您的設備能夠看見。'
  },
  promotions: '活動促銷',
  explore: '探索更多',
  learn:'學習更多',
  fingerNews:'Finger News',
  //-----------------------表單-----------------------
  //登錄相關
  openAccount: '新開賬戶',
  joinUs: '加入我們',
  logout: '登出賬號',
  login: '登入賬戶',
  signIn: '開始登錄',
  signUp: '註冊會員',
  verificationCode: '驗證碼',
  CheckEmail: '請檢查您的電子信箱',
  enterEmail: '請輸入您的電子信箱地址',
  enterPassword: '請輸入您的密碼',
  RememberMe: 'Remember Me',
  forgotPasswoed: 'Forgot Passwoed?',
  enterBroker: 'Enter Your Broker Number',
  email: '電子信箱',
  password: 'Password',
  userCenter: '用戶中心',
  //-------------------登錄相關文字------------------------
  welcomeBack: 'Welcome Back',
  hellothere: 'Hello there,',
  loginText1: 'Enter your personal details and start journey with us',
  loginText2: 'Encountered a problem?',
  loginText3: 'Dont hane an acount?',
  loginText4: 'Download APP:',
  //-------------------註冊相關文字------------------------
  signUpText1: 'Welcome To Join Us',
  signUpText2: 'To Keep Connected with us please login with your personal info',
  dontAccount: 'Dont have an account?',
  alreadyAccount: 'Already have an account?',
  //---------------------設定界面-------------------------
  generalProfole: 'General Profole',
  personalInformation: 'Personal Information',
  security: 'Security Setting',
  investmentPreference: 'Investment Preference',
  brokersSetting: 'Brokers Setting',

  generalProfoleText: '完成个性化设定将享受定制型服务并保障账户安全，快来尝试吧！',
  personalInformationText: 'You can modify your personal information here',
  securityText: 'Full security Settings can make your account more secure',
  investmentPreferenceText: 'Setting your investment preferences helps you better control your investment risk',
  brokersSettingText: 'You can freely choose your favorite broker here, enjoy their quality service',
  //---------------------個人設定-------------------------
  avatar: 'Avatar:',
  nickName: 'Nick Name:',
  birth: 'Birth',
  gender: 'Gender',
  male: 'MALE',
  female: 'FEMALE',
  phone: 'Phone',
  emailSetting: 'E-mail Setting',
  commonNotice: 'Common Notice',
  commonNotice1: '我願意接收finger的廣告及活動通知',
  commonNotice2: '我希望finger在我關注的產品達到預期價格時通知我',
  commonNotice3: '我持倉的產品發生超過10%變動時候提示我',
  securityNotice: 'Security Notice',
  securityNotice1: '在我登錄finger時提示我',
  securityNotice2: '異常的IP地址登錄',
  tradingNotice: 'Trading Notice',
  tradingNotice1: '在每次成功交易時通知我',
  tradingNotice2: '在每次賬戶金額有變化時通知我',
  //---------------------安全設置-------------------------
  security1: 'Setting your investment preferences helps you better control your investment risk',
  tWOSTEP: 'TWO-STEP VERIFICATION',
  commonEquipment: 'Common Equipment',
  passwordHint: 'Password Hint',
  changePassword: 'Change Password:',
  changeEmail: 'Change Email:',
  changeMobile: 'Change Mobile:',
  privacySetting: 'Privacy Setting',
  privacySetting1: 'Make Your Email Public',
  privacySetting2: 'Make Your Base Information Public',
  privacySetting3: 'Make Your Base Trading Public',
  privacySetting4: 'Make Your Base Holding Public',
  //-----------------首頁----------------------
  index1: 'Finger Trading',
  index2: 'Easy Safety Efficient',
  index3: 'Fingertip trading is a professional fashion trading software,It brings together over 10,000 products from 54 exchanges around the world,More than 100 securities sector through rigorous and flexible Api access to provide services, truly achieve an account, holding the world.',



  //-----------------產品股票----------------------
  stockIndex1: 'Trade Stocks Globally',
  stockIndex2: 'from a Single Account',
  stockIndex3: '利用Finger的優勢，透過0傭金且簡易人性化的實用新投資方式強化自己的股票價值。',
  stockIndex4: 'Why Finger?',
  stockIndex5: 'Our tools, information and professional guidance allow you to never have to face the market.',
  stockIndex6: 'Low commissions with Finger SmartRoutingSM.',
  stockIndex7: 'Trade stocks globally on 80+ market centers.',
  stockIndex8: 'Professional trading platforms, order types, and tools.',
  stockIndex9: 'Lowest financing rates in the industry.',
  stockIndex10: 'Fractional Shares let you invest regardless of share price',
  stockIndex11: 'Transparent shortable inventory and pricing',
  stockIndex12: 'Earn extra income on your fully paid shares',
  stockIndex13: 'Why trade stocks?',
  stockIndex14: 'Tocks let you own a piece of a company’s future. They’re available for a wide variety of industries—so you can tap into your knowledge of specific businesses, or help you to diversify your portfolio.',
  stockIndex15: 'Growth potential',
  stockIndex16: 'While stock performance changes over time, successful stocks can help your money grow—at times, they can even outrun inflation',
  stockIndex17: 'Income',
  stockIndex18: 'Some stocks pay regular dividends—that’s income you can keep or reinvest',
  stockIndex19: 'Flexibility',
  stockIndex20: 'Since stocks trade by the millions every day, you can move quickly when you’re buying or selling',
  stockIndex21: 'Stock & market board',

  //-----------------外匯產品頁面----------------------
  forexIndex1: 'Benefits of Forex',
  forexIndex2: 'LIQUIDITY',
  forexIndex3: 'The forex market is the largest and most liquid financial market. Daily activity often exceeds $4 trillion USD, with over $1.5 trillion of that conducted in the form of spot trading.',
  forexIndex4: 'VOLATILITY',
  forexIndex5: 'Volatility boosts opportunity due to exchange rate fluctuations. Forex trading operates 24 hours a day, 5 days a week. The greatest liquidity occurs when operational hours in multiple time zones overlap.',
  forexIndex6: 'TIGHT SPREADS',
  forexIndex7: 'Spreads in the forex market tend to be tighter (less), than the spreads applied to other securities such as stocks. This makes OTC forex trading one of the most cost-effective means of investment trading.',
  forexIndex8: 'Tight spreads as narrow as 1/10 PIP',
  forexIndex9: "Deep liquidity and real time quotes from 17 of the world's largest FX dealers",
  forexIndex10: '100+ currency pairs available',
  forexIndex11: 'Professional FX trading tools',
  forexIndex12: 'Rated 4.5/5 stars Overall in the ForexBrokers.com 2021 Annual Review',
  forexIndex13: 'Low commissions, 0.08 to 0.20 bps times trade size, with no hidden spreads or markups',
  forexIndex14: 'TRADING ESSENTIALS',
  forexIndex15: 'Learn all the trading fundamentals before you start placing trading orders. Practice these key elements on your demo account using virtual money, and get ready to trade CFD and forex live in real-time with your account balance.',
  forexIndex16: 'TRADING ESSENTIALS',
  forexIndex17: 'The Exchange Rate is the price for which foreign currencies are traded. The Quote List and Quote Panel show different views of the current Finger exchange (buy/sell) rates in real time. When there is movement in the exchange rate for a currency pair, the green and red up/down indicators alert traders to momentary changes in direction.',
  forexIndex18: 'CURRENCY PAIRS',
  forexIndex19: 'Currency pairs are always listed in the same order. For example,the most commonly-traded currency pair consists of the Euro and the U.S. dollar. This pair is always listed as EUR/USD and never the reverse order. In this example EUR is is the BASE currency, and USD is the QUOTE currency. When published with an exchange rate, the currency pair indicates how much of the quote currency is required to purchase one unit of the base currency.',
  forexIndex20: 'SPREAD',
  forexIndex21: 'The SPREAD is the difference between the two prices. The bid price is always less than the ask price because brokers pay less than they receive for the same currency pair. This difference – known as the spread – is how your broker is compensated for their services in executing your trade. Some brokers, including Finger, also offer pricing options that include a commission combined with lower spreads. A spread is commonly measured in pips. The pip is the value of the 4th decimal place for pairs other than for JPY, where it is 2nd decimal place. Finger, however, offers increased pricing transparency by displaying 5decimal places for currency pairs, other than JPY in which 3 decimal places are shown. This is called a pipette.',
  forexIndex22: 'BID PRICE',
  forexIndex23: 'The BID price is the rate that your broker is willing to pay for the currency pair; in other words, this is the rate you receive if selling to the market. For example in the rate panel shown, the bid price is 1.05761. You could sell one Euro for 1.05761 US dollars.',
  forexIndex24: 'ASK PRICE',
  forexIndex25: 'The ASK price is the rate at which your broker is willing to sell and represents the rate you must pay to buy the base currency. In the example 1 Euro will cost you 1.05775 US dollars.',
  forexIndex26: 'CLOSING A TRADE',
  forexIndex27: 'Active trades are referred to as open positions and are subject to fluctuations in the exchange rate. Open positions are closed by entering into a trade that takes the opposite position to the original trade, bringing the total amount for the currency pair derivative back to zero. The Finger trading platform automates the process of closing a position for you. For example, if you have a short position consisting of 50,000 units of USD/CAD, you only need to click a single button to create and execute a buy order for 50,000 USD/CAD to close your position and realize your return.',
  forexIndex28: 'REALIZING GAINS/LOSSES',
  forexIndex29: 'Only when you close a position do you realize the actual gains or losses for the trade, thereby affecting the actual cash balance of your account. It is important to understand that gains or losses for open positions are still unrealized. ',
  forexIndex30: 'CLOSING A LONG POSITION',
  forexIndex31: 'To close a long position, you must sell an equal amount of the same currency pair derivative to reduce your long position to zero. If you receive more when you sell than you paid to buy the order, you earn a profit. If you receive less, you realize a loss.',
  forexIndex32: 'CLOSING A SHORT POSITION',
  forexIndex33: 'A short position is the opposite of a long position. In order to close a short position, you would need to buy enough of the currency pair derivative to bring your position back to zero. If you can buy this back for less than you earned when you sold it originally, the difference is retained as profit.',
  forexIndex34: 'PARTIAL POSITION CLOSE',
  forexIndex35: 'It is possible to partially close an open position by only selling or buying enough to partly offset the open position. For example, selling only $75,000 when you have an open position of $100,000 EUR/USD, closes three-quarters of the original position, leaving an open EUR/USD position of $25,000.',
  forexIndex36: 'Trade Stocks Globally',
  forexIndex37: 'from a Single Account',
  forexIndex38: '利用Finger的優勢，透過0傭金且簡易人性化的實用新投資方式強化自己的股票價值。',


  //-----------------期貨產品頁面----------------------
  futuresIndex1: "Futures Trade",
  futuresIndex2: "Traders and investors use the term futures in reference to the overall asset class. However, there are many types of futures contracts available for trading including:",
  futuresIndex3: "Commodity futures such as crude oil, natural gas, corn, and wheat",
  futuresIndex4: "Stock index futures such as the S&P 500 Index",
  futuresIndex5: "Currency futures including those for the euro and the British pound",
  futuresIndex6: "Precious metal futures for gold and silver",
  futuresIndex7: "U.S. Treasury futures for bonds and other products",
  futuresIndex8: "The futures markets typically use high leverage. Leverage means that the trader does not need to put up 100% of the contract's value amount when entering into a trade. Instead, the broker would require an initial margin amount, which consists of a fraction of the total contract value.",
  futuresIndex9: "Regulation of Futures",
  futuresIndex10: "The futures markets are regulated by the Commodity Futures Trading Commission (CFTC). The CFTC is a federal agency created by Congress in 1974 to ensure the integrity of futures market pricing, including preventing abusive trading practices, fraud, and regulating brokerage firms engaged in futures trading.",
  futuresIndex11: "Frequently Asked Questions",
  futuresIndex12: "What Are Futures Contracts?",
  futuresIndex13: "Futures contracts are an investment vehicle that allows the buyer to bet on the future price of a commodity or other security. There are many types of futures contracts available, on assets such as oil, stock market indices, currencies, and agricultural products.",
  futuresIndex14: "Unlike forward contracts, which are customized between the parties involved, futures contracts trade on organized exchanges such as those operated by the CME Group Inc. (CME). Futures contracts are popular among traders, who aim to profit on price swings, as well as commercial customers who wish to hedge their risks",
  futuresIndex15: "Are Futures a Type of Derivative?",
  futuresIndex16: "Yes, futures contracts are a type of derivative product. They are derivatives because their value is based on the value of an underlying asset, such as oil in the case of crude oil futures. Like many derivatives, futures are a leveraged financial instrument, offering the potential for outsize gains or losses. As such, they are generally considered to be an advanced trading instrument and are mostly traded only by experienced investors and institutions.",
  futuresIndex17: "What Happens if You Hold a Futures Contract Until Expiration?",
  futuresIndex18: "Oftentimes, traders who hold futures contracts until expiration will settle their position in cash. In other words, the trader will simply pay or receive a cash settlement depending on whether the underlying asset increased or decreased during the investment holding period.",
  futuresIndex19: "In some cases, however, futures contracts will require physical delivery. In this scenario, the investor holding the contract upon expiration would be responsible for storing the goods and would need to cover costs for material handling, physical storage, and insurance.",
  futuresIndex20: "Trade Stocks Globally",
  futuresIndex21: "from a Single Account",
  futuresIndex22: "利用Finger的優勢，透過0傭金且簡易人性化的實用新投資方式強化自己的股票價值。",

  //-----------------基金產品頁面----------------------
  fundsIndex1:"Common Types of Funds",
  fundsIndex2:"The following are examples of funds commonly used for personal ventures:",
  fundsIndex3:"Emergency funds are personal savings vehicles created by individuals used to cover periods of financial hardships, such as job loss, prolonged illness, or a major expense. The rule of thumb is to create an emergency fund that contains at least three months worth of net income.",
  fundsIndex4:"Money-market funds are highly liquid mutual funds purchased to earn interest for investors through short-term interest-bearing securities, such as Treasury bills and commercial paper.",
  fundsIndex5:"College funds are usually tax-advantaged savings plans set up by families to allocate funds for their children’s college expenses.",
  fundsIndex6:"Exchange-traded funds (ETFs) are similar to mutual funds but are traded on public exchanges (similar to stocks).",
  fundsIndex7:"Trust funds are legal arrangements set up by a grantor who appoints a trustee to administer valuable assets for the benefit of a listed beneficiary for a period of time, after which all or a portion of the funds are released to the beneficiary or beneficiaries.",
  fundsIndex8:"Hedge funds are investment vehicles for high-net-worth individuals or institutions designed to increase the return on investors’ pooled funds by incorporating high-risk strategies such as short selling, derivatives, and leverage.",
  fundsIndex9:"Retirement funds are savings vehicles used by individuals saving for retirement. Retirees receive monthly income or pensions from retirement funds.",
  fundsIndex10:"Government bond funds are for investors looking to put their money away in low-risk investments through Treasury securities—such as Treasury bonds—or agency-issued debt—such as securities issued by Fannie Mae. Both alternatives are backed by the U.S. government.",
  fundsIndex11:"Mutual funds are investment funds managed by professional managers who allocate the funds received from individual investors into stocks, bonds, and/or other assets.",
  fundsIndex12:"Identifying Goals and Risk Tolerance",
  fundsIndex13:"Before investing in any fund, you must first identify your goals for the investment. Is your objective long-term capital gains, or is current income more important? Will the money be used to pay for college expenses, or to fund a retirement that's decades away? Identifying a goal is an essential step in whittling down the universe of more than 8,000 mutual funds available to investors.",
  fundsIndex14:"You should also consider personal risk tolerance. Can you accept dramatic swings in portfolio value? Or, is a more conservative investment more suitable? Risk and return are directly proportional, so you must balance your desire for returns against your ability to tolerate risk.",
  fundsIndex15:"Before investing in any fund, you must first identify your goals for the investment.",
  fundsIndex16:"A prospective mutual fund investor must also consider personal risk tolerance.",
  fundsIndex17:"A potential investor must decide how long to hold the mutual fund.",
  fundsIndex18:"There are several major alternatives to investing in mutual funds, including exchange-traded funds (ETFs).",
  fundsIndex19:"Trade Stocks Globally",
  fundsIndex20:"from a Single Account",
  fundsIndex21:"利用Finger的優勢，透過0傭金且簡易人性化的實用新投資方式強化自己的股票價值。",

  //-----------------債券產品頁面----------------------
  bondIndex1: "What Is a Bond?",
  bondIndex2: "A bond is a fixed income instrument that represents a loan made by an investor to a borrower (typically corporate or governmental). A bond could be thought of as an I.O.U. between the lender and borrower that includes the details of the loan and its payments. Bonds are used by companies, municipalities, states, and sovereign governments to finance projects and operations. Owners of bonds are debtholders, or creditors, of the issuer.",
  bondIndex3: "Bond details include the end date when the principal of the loan is due to be paid to the bond owner and usually include the terms for variable or fixed interest payments made by the borrower.",
  bondIndex4: "Choosing bonds",
  bondIndex5: "Maturity & duration",
  bondIndex6: "A bond's maturity refers to the length of time until you'll get the bond's face value back.",
  bondIndex7: "As with any other kind of loan—like a mortgage—changes in overall interest rates will have more of an effect on bonds with longer maturities.",
  bondIndex8: "For example, if current interest rates are 2% lower than your rate on a mortgage on which you have 3 years left to pay, it's going to matter much less than it would for someone who has 25 years of mortgage payments left.",
  bondIndex9: "Because bonds with longer maturities have a greater level of risk due to changes in interest rates, they generally offer higher yields so they're more attractive to potential buyers. The relationship between maturity and yields is called the yield curve.",
  bondIndex10: "In a normal yield curve, shorter maturities = lower yields",
  bondIndex11: "Bond Ratings",
  bondIndex12: "Most bonds come with a rating that outlines their quality of credit. That is, how strong the bond is and its ability to pay its principal and interest. Ratings are published and are used by investors and professionals to judge their worthiness.",
  bondIndex13: "The most commonly cited bond rating agencies are Standard & Poor’s, Moody's Investors Service, and Fitch Ratings. They rate a company’s ability to repay its obligations. Ratings range from AAA to Aaa for high-grade issues very likely to be repaid to D for issues that are currently in default.2",
  bondIndex14: "Bonds rated BBB to Baa or above are called investment grade. This means they are unlikely to default and tend to remain stable investments. Bonds rated BB to Ba or below are called junk bonds—default is more likely, and they are more speculative and subject to price volatility.",
  bondIndex15: "Firms will not have their bonds rated, in which case it is solely up to the investor to judge a firm’s repayment ability. Because the rating systems differ for each agency and change from time to time, research the rating definition for the bond issue you are considering.",
  bondIndex16: "Trade Stocks Globally",
  bondIndex17: "from a Single Account",
  bondIndex18: "利用Finger的優勢，透過0傭金且簡易人性化的實用新投資方式強化自己的股票價值。",


  //-----------------ETF產品頁面----------------------
  etfIndex1:"Benefits of ETFs",
  etfIndex2:"ETFs can offer lower operating costs than traditional open-end funds, flexible trading, greater transparency, and better tax efficiency in taxable accounts.",
  etfIndex3:"Trading flexibility",
  etfIndex4:"Traditional open-end mutual fund shares are traded only once per day after the markets close. All trading is done with the mutual fund company that issues the shares. Investors must wait until the end of the day when the fund net asset value (NAV) is announced before knowing what price they paid for new shares when buying that day and the price they will receive for shares they sold that day. Once-per-day trading is fine for most long-term investors, but some people require greater flexibility.",
  etfIndex5:"Portfolio diversification and risk management",
  etfIndex6:"Investors may wish to quickly gain portfolio exposure to specific sectors, styles, industries, or countries but do not have expertise in those areas. Given the wide variety of sector, style, industry, and country categories available, ETF shares may be able to provide an investor easy exposure to a specific desired market segment.",
  etfIndex7:"Lower costs",
  etfIndex8:"Operating expenses are incurred by all managed funds regardless of the structure. Those costs include, but are not limited to, portfolio management fees, custody costs, administrative expenses, marketing expenses, and distribution. Costs historically have been very important in forecasting returns. In general, the lower the cost of investing in a fund, the higher the expected return for that fund.",
  etfIndex9:"Tax benefits",
  etfIndex10:"ETFs have 2 major tax advantages compared to mutual funds. Due to structural differences, mutual funds typically incur more capital gains taxes than ETFs. Moreover, capital gains tax on an ETF is incurred only upon the sale of the ETF by the investor, whereas mutual funds pass on capital gains taxes to investors through the life of the investment. In short, ETFs have lower capital gains and they are payable only upon sales of the ETF.",
  etfIndex11:"Types of ETFs",
  etfIndex12:"There are various types of ETFs available to investors that can be used for income generation, speculation, price increases, and to hedge or partly offset risk in an investor's portfolio. Here is a brief description of some of the ETFs available on the market today.",
  etfIndex13:"Bond ETFs",
  etfIndex14:"Bond ETFs are used to provide regular income to investors. Their income distribution depends on the performance of underlying bonds. They might include government bonds, corporate bonds, and state and local bonds—called municipal bonds. Unlike their underlying instruments, bond ETFs do not have a maturity date. They generally trade at a premium or discount from the actual bond price. You can read more about bond ETFs here.",
  etfIndex15:"Stock ETFs",
  etfIndex16:"Stock ETFs comprise a basket of stocks to track a single industry or sector. For example, a stock ETF might track automotive or foreign stocks. The aim is to provide diversified exposure to a single industry, one that includes high performers and new entrants with potential for growth. Unlike stock mutual funds, stock ETFs have lower fees and do not involve actual ownership of securities. You can read more about stock ETFs here.",
  etfIndex17:"Industry ETFs",
  etfIndex18:"Industry or sector ETFs are funds that focus on a specific sector or industry. For example, an energy sector ETF will include companies operating in that sector. The idea behind industry ETFs is to gain exposure to the upside of that industry by tracking the performance of companies operating in that sector. One example is the technology sector, which has witnessed an influx of funds in recent years. At the same time, the downside of volatile stock performance is also curtailed in an ETF because they do not involve direct ownership of securities. Industry ETFs are also used to rotate in and out of sectors during economic cycles. You can read more about sector ETFs here.",
  etfIndex19:"Commodity ETFs",
  etfIndex20:"As their name indicates, commodity ETFs invest in commodities, including crude oil or gold. Commodity ETFs provide several benefits. First, they diversify a portfolio, making it easier to hedge downturns. For example, commodity ETFs can provide a cushion during a slump in the stock market. Second, holding shares in a commodity ETF is cheaper than physical possession of the commodity. This is because the former does not involve insurance and storage costs. You can read more about commodity ETFs here.",
  etfIndex21:"Currency ETFs",
  etfIndex22:"Currency ETFs are pooled investment vehicles that track the performance of currency pairs, consisting of domestic and foreign currencies. Currency ETFs serve multiple purposes. They can be used to speculate on the prices of currencies based on political and economic developments for a country. They are also used to diversify a portfolio or as a hedge against volatility in forex markets by importers and exporters. Some of them are also used to hedge against the threat of inflation. You can read more about currency ETFs here.",
  etfIndex23:"Inverse ETFs",
  etfIndex24:"Inverse ETFs attempt to earn gains from stock declines by shorting stocks. Shorting is selling a stock, expecting a decline in value, and repurchasing it at a lower price. An inverse ETF uses derivatives to short a stock. Essentially, they are bets that the market will decline. When the market declines, an inverse ETF increases by a proportionate amount. Investors should be aware that many inverse ETFs are exchange traded notes (ETNs) and not true ETFs. An ETN is a bond but trades like a stock and is backed by an issuer like a bank. Be sure to check with your broker to determine if an ETN is a good fit for your portfolio.",
  etfIndex25:"In the U.S., most ETFs are set up as open-ended funds and are subject to the Investment Company Act of 1940 except where subsequent rules have modified their regulatory requirements. Open-end funds do not limit the number of investors involved in the product.",
  etfIndex26:"ETFs vs. Mutual Funds vs. Stocks",


  //-----------------服務頁面----------------------
  serviceIndex1: "根據角色類型",
  serviceIndex2: "選擇一種身分或服務，詳細了解Finger提供甚麼服務。",

  //-----------------投資客頁面----------------------
  investorIndex1: "投資客",    
  investorIndex2: "Are you ready?",    
  investorIndex3: "Scroll Down",    
  investorIndex4: "一個帳號",    
  investorIndex5: "全球交易",    
  investorIndex6: "在沒有Finger之前，您是否擁有來自不同市場不同金融產品的多個APP，在不同的語言及操作系統之間切換會造成諸多不便，甚至會煩躁不堪錯過良好的投資機會，造成不必要的損失。",    
  investorIndex7: "集合大量優秀卷商",    
  investorIndex8: "多樣選擇充分競爭，導致最低廉的交易成本",    
  investorIndex9: "券商們通過連接Finger的API接口來提供服務，您的交易信號通過Finger的客戶終端發送信號給您所選擇的券商，券商接受信號後投入市場並成交，就像您家中的接線板，Finger更像是一個遍佈全球的萬用接線板。",    
  investorIndex10: "遍佈全球的雲伺服器",    
  investorIndex11: "提供快速便捷的雲交易",    
  investorIndex12: "FInger採用的是雲服務器，在全球大多數國家和州都擁有伺服器，同時科學的軟體設計也是我們保證數據快速的基石，我們採用了微服務佈局，把更多的空間和性能單獨提供給了交易核心，和傳統的軟體設計相比，我們有著更多的靈活性和安全性。",    
  investorIndex13: "機器人AI服務，依託大量數據提供最優秀的算法給您提供最權威的數據統計，輔助交易。",    
  investorIndex14: "Finger News",    
  investorIndex15: "Finger News是我們的信息平台，同時我們提供了社交功能，您可以在裡面搜索到來自全球Finger的用戶的最新消息，同時我們開放了專業分析師註冊，您也能在這裡收到來自專業分析師的分析報告。",    
  investorIndex16: "社交平台，討論投資心得",    
  investorIndex17: "可選的公開交易訊息",    
  investorIndex18: "杜絕虛假專家",    
  investorIndex19: "最全的企業資訊&新聞",    
  investorIndex20: "優秀貼心的私人管家，更好的輔助您的工作",    
  investorIndex21: "貼心私人管家",    
  investorIndex22: "7 X 24小時全方位的私人管家，隨時登錄點擊您的私人天地，1對1管家會竭盡可能的滿足您的要求，免除您在互聯網上搜尋來源不明的數據，但管家沒有推薦和分析股票的能力和相關執業資格，如專業問題，請聯繫您的執業分析師。",    
  investorIndex23: "折扣生活化",    
  investorIndex24: "於我們合作的多家企業竭盡全力的為您獲得最佳的折扣，在Finger的用戶獨享的折扣價格。",    
  investorIndex25: "制定服務",    
  investorIndex26: "我們的大數據將為您提供最符合人性的服務，人工智能將成為最了解您的“人”，為您定制最特殊的服務。",   

  //-----------------經紀人頁面----------------------
  brokerIndex1: "經紀人",
  brokerIndex2: "You are BOSS.",
  brokerIndex3: "Scroll Down",
  brokerIndex4: "您為自己工作",
  brokerIndex5: "做自己的BOSS",
  brokerIndex6: "在互聯網時代的來臨中，人們的工作和生活習慣將大幅度改變，我們將花更多的時間在家裡，在手機上工作，隨著科技的發展，這會更有效率，實驗證明，在公司的工作大部分時間的工作效率是更低的，自己調配時間，加上軟件的輔助，讓您提高工作效率的同時，盡情享受家庭的溫馨。",
  brokerIndex7: "直接對接卷商，拿到全球最優秀的折扣及傭金",
  brokerIndex8: "券商們通過連接Finger的API接口來提供服務，您的交易信號通過Finger的客戶終端發送信號給您所選擇的券商，券商接受信號後投入市場並成交，就像您家中的接線板，Finger更像是一個遍佈全球的萬用接線板。",
  brokerIndex9: "大市場",
  brokerIndex10: "為全球用戶提供服務",
  brokerIndex11: "FInger是一家面向全球的交易軟體提供商，在全球都有推廣及服務，並擁有大量的合作夥伴，所以在Finger你可以擁有更廣闊的的空間，服務于全球市場。",
  brokerIndex12: "Finger News是我們的信息平台，同時我們提供了社交功能，您可以在裡面搜索到來自全球Finger的用戶的最新消息，同時我們開放了專業分析師註冊，您也能在這裡收到來自專業分析師的分析報告。",
  brokerIndex13: "最全的資訊&擴大傳播",
  brokerIndex14: "如果您有分析師和經紀人執業牌照，您可以註冊成為分析師提供您的見解，打造自己的權威品牌，並經營它，您能同時能通過粉絲及點擊量獲得高額回報及收益，我們期待您加入Finger，解放自己的工作及生活。",
  brokerIndex15: "社交平台，方便溝通",
  brokerIndex16: "我們開放了社交功能，您能在Finger News交到更多志同道合的朋友，在Finger有來自全球的高端投資用戶匯聚，您能在愉快交友的同時，維護自己交際圈的質量，學到更多，讓對話更有營養",
  brokerIndex17: "Finger和我們的合作夥伴是您最強大的背景",
  brokerIndex18: "全美有3700家證券經紀商牌照，但擁有自己交易系統的不到10%，放眼世界，比例更低，根據我們再歐洲，澳大利亞的經營經驗，我們能理解到高額的製作費和運營成本讓多數資本金不多的經紀商無法給自己的用戶提供電子盤交易，Finger會讓您用低廉的成本擁有全世界領先的交易軟體。",

  //-----------------經紀商頁面----------------------
  brokersIndex1: "經紀商",
  brokersIndex2: "Let's do it!!",
  brokersIndex3: "為全球用戶提供服務",
  brokersIndex4: "增加產品曝光率",
  brokersIndex5: "FInger採用的是雲服務器，在全球大多數國家和州都擁有伺服器，同時科學的軟體設計也是我們保證數據快速的基石，我們採用了微服務佈局，把更多的空間和性能單獨提供給了交易核心，和傳統的軟體設計相比，我們有著更多的靈活性和安全性。",
  brokersIndex6: "全方位的管理後台",
  brokersIndex7: "全美有3700家證券經紀商牌照，但擁有自己交易系統的不到10%，放眼世界，比例更低，根據我們再歐洲，澳大利亞的經營經驗，我們能理解到高額的製作費和運營成本讓多數資本金不多的經紀商無法給自己的用戶提供電子盤交易，Finger會讓您用低廉的成本擁有全世界領先的交易軟體。",
  brokersIndex8: "優秀軟體設計",
  brokersIndex9: "靈活對接API",
  brokersIndex10: "我們提供了豐富的API接口去適應您所提供的的不同品種和種類的產品及滿足各國的交易所交易求，您可以經過快速簡單的操作對接到Finger的交易系統中，同時正如我們所說，為了更好的降低您的運營成本，把主要精力放到業務中，我們也提供了安全高效的結算系統，如果您的公司擁有結算資格，歡迎於我們聯絡",
  brokersIndex11: "最全的資訊&擴大傳播",
  brokersIndex12: "Finger News匯聚了來自全球的金融人群，不乏專業的投資客和分析師及優秀的經紀人，我們十分推薦您經營好自己的經紀商賬戶，這能更好的維護您的品牌及他在全球的效應，您可以作為經紀商發表您的報告及消息，但請注意對言論負責，品牌的建立是一個長期誠信，負責的過程。",
  brokersIndex13: "社交平台，方便溝通",
  brokersIndex14: "Finger提供了市面上大部分社交軟體都有的功能，這能有效的方便我們的用戶社交，並能保持社區的活躍，經紀商也可以通過這項功能更好的服務用戶，甚至遠程辦公，我們相信隨著互聯網的發展，越來越多的工作模式都會更自由，越來越多人會通過互聯網在家辦公，這也是我們提供社交功能的主要原因",
  brokersIndex15: "私人管家系統",
  brokersIndex16: "讓您和客戶零距離",
  brokersIndex17: "對1的私人管家服務能夠讓您和客戶之間保持零距離，我們在三個國家的客服團隊提供三種不同語言的服務可以輔助您提高服務質量，提高您的工作效率",

  //-----------------分析師頁面----------------------
  analystsIndex1: "分析師",
  analystsIndex2: "We need you!",
  analystsIndex3: "多樣性的收入機會",
  analystsIndex4: "付費訂閱，在您提供專業意見的同時，增加收入。",
  analystsIndex5: "點擊付費，根據流量我們會和你分享廣告收入",
  analystsIndex6: "我們是一家軟體公司，廣告並非我們的主營業務，所以我們有著全網最優秀的分成比例，最高可拿到8成。",
  analystsIndex7: "集合大量優秀卷商",
  analystsIndex8: "多樣選擇充分競爭，導致最低廉的交易成本",


}
