<template>
  <div id="footbar">
    <div class="rulesBox">
      <div class="rulesTitle" v-for="item in rulesList" :key="item">
        <div class="text">{{ item.text }}</div>
      </div>
    </div>
    <div class="menuBox">
      <div class="menuItem" v-for="item in menuList" :key="item">
        <div class="menuTitle">{{ item.title }}</div>
        <div class="menuText" v-for="subItem in item.list" :key="subItem">
          {{ subItem.text }}
        </div>
      </div>
    </div>
    <div class="bottom-box">Copyright © finger trading Inc.</div>
  </div>
</template>

<script>
import UrlUtils from '@/utils/url';
// import DownloadAppItem from '../downloadAppItem/DownloadAppItem.vue';
export default {
  name: 'FootBar',
  components: {
    // DownloadAppItem
  },
  data () {
    return {
      rulesList: [
        { "text": this.$t('lang.foot.text1') },
        { "text": this.$t('lang.foot.text2') },
        { "text": this.$t('lang.foot.text3') },
      ],

    }
  },
  methods: {
    goLogin () {
      window.location.href = UrlUtils.loginUrl
    },
    goSignUp () {
      window.location.href = UrlUtils.signUpUrl
    }
  },
  computed: {
    menuList () {
      return [
        {
          title: this.$t('lang.navMenu.menu2'), list: [
            { text: this.$t('lang.navMenu.menu11') },
            { text: this.$t('lang.navMenu.menu12') },
            { text: this.$t('lang.navMenu.menu13') },
          ]
        },
        {
          title: this.$t('lang.navMenu.service'), list: [
            { text: this.$t('lang.navMenu.forInvestor') },
            { text: this.$t('lang.navMenu.forBroker') },
            { text: this.$t('lang.navMenu.forAnalyst') },
            { text: this.$t('lang.navMenu.forBrokerageHouse') },
            { text: this.$t('lang.navMenu.forDeveloper') }
          ]
        },
        {
          title: this.$t('lang.navMenu.menu1'), list: [
            { text: this.$t('lang.navMenu.general') },
            { text: this.$t('lang.navMenu.develop') }
          ]
        },
        {
          title: "App & Download", list: [
            { text: "ISO" },
            { text: "Web" },
            { text: "Android" }
          ]
        },
      ]
    }
  }
}
</script>

<style lang='scss' scoped>
#footbar {
  position: relative;
  width: 100vw;
  padding: 0 14%;
  @include background_color('bg-nav');
  box-shadow: $foot-shadow;
  .lineOne {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: space-between;
    color: aliceblue;
    border-bottom: 1px solid rgb(67, 67, 67);
    .downloadBox {
      display: flex;
      align-items: center;
      .downloadBtnBox {
        margin-left: 40px;
        & svg {
          margin-right: 20px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .loginBox {
      display: flex;
      align-items: center;
      min-width: 400px;
      height: 80px;
      .btn {
        padding: 6px 40px;
        font-weight: 600;
        color: aliceblue;
        margin-right: 20px;
        background: #000;
        border-radius: 6px;
        transition: 0.4s;
        &:nth-child(1) {
          background: #fff;
          color: #000;
        }
        &:hover {
          cursor: pointer;
          background: gray;
        }
      }
    }
  }

  .rulesBox {
    padding-top: 40px;
    padding-bottom: 30px;
    min-height: 60px;
    border-bottom: 1px solid rgb(67, 67, 67);
    color: rgb(189, 189, 189);
    text-align: start;
    font-size: 13px;
    .rulesTitle {
      margin-bottom: 10px;
    }
  }
  .menuBox {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 40px 0;
    border-bottom: 1px solid rgb(67, 67, 67);
    .menuItem {
      position: relative;
      text-align: start;
    }
    .menuText {
      position: relative;
      font-size: 14px;
      line-height: 30px;
      text-align: start;
      height: 30px;
      width: 100%;
      color: aliceblue;
    }

    .menuText:hover {
      color: rgb(108, 134, 158);
      cursor: pointer;
    }

    .menuTitle {
      font-weight: 900;
      color: aliceblue;
      margin-bottom: 10px;
    }
  }
  .bottom-box {
    text-align: center;
    @include color('font_color');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
  }
}
</style>
